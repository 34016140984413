<template>
  <div v-if="authStore.loggedIn" class="grid gap-y-2">
    <Card class="p-0">
      <template #title>
        <h1 class="text-center">Stats in the last 24 hours</h1>
      </template>
    </Card>
    <Card v-if="failedToLoadStats" class="p-0">
      <template #content>
        <p class="text-center text-red-500">
          There was an issue updating the stats. Please try again later.
        </p>
      </template>
    </Card>

    <Card class="p-0">
      <template #title>
        <h6 class="text-lg mb-2">Total Automation Impact</h6>
        <hr />
      </template>
      <template #content>
        <div class="mt-2">
          <div v-if="isLoading" class="text-center">
            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" />
          </div>
          <div v-else class="flex justify-around">
            <div>
              <div class="flex flex-row justify-center items-center gap-3">
                <div class=""><i class="pi pi-file-check text-3xl"></i></div>
                <div>
                  <div class="text-sm">Total Tasks</div>
                  <div class="text-3xl text-center">{{ formatNumberWithCommas(totalTasks) }}</div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex flex-row justify-center items-center gap-3">
                <div class=""><i class="pi pi-clock text-3xl"></i></div>
                <div>
                  <div class="text-sm">Total Time Saved</div>
                  <div class="text-3xl text-center">
                    {{ formatDurationFromSecs(totalTimeSaved) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card class="p-0" v-for="[stat, total] in stats" :key="stat.task_name">
      <template #title>
        <div class="flex gap-3 items-center">
          <div class=""><i class="pi text-lg" :class="statIcon[stat]"></i></div>
          <div>
            <h6 class="text-lg">{{ stat }}</h6>
          </div>
        </div>
        <!-- <hr /> -->
      </template>
      <template #content>
        <div class="mt-2">
          <div v-if="isLoading" class="text-center">
            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" />
          </div>
          <div v-else class="text-3xl">{{ formatNumberWithCommas(total) }}</div>
        </div>
      </template>
    </Card>
  </div>
  <Card v-else>
    <template #content>
      <h6 class="text-lg font-bold text-center py-2">
        Login or create an account to start tracking your stats.
      </h6>
    </template>
  </Card>
</template>

<script setup lang="ts">
import Card from 'primevue/card'
import ProgressSpinner from 'primevue/progressspinner'
import { fetchStats } from '@/services/stats'
import { ref, onMounted } from 'vue'
import StatTask, { statIcon } from '@/enums/StatsTask'
import { useAuthStore } from '@/stores/auth'
import { formatDurationFromSecs, formatNumberWithCommas } from '@/utils'

const stats = ref<any>(new Map<StatTask, number>())
const authStore = useAuthStore()
const isLoading = ref(true)
const totalTimeSaved = ref(0)
const totalTasks = ref(0)
const failedToLoadStats = ref<boolean>(false)

Object.values(StatTask).map((value) => stats.value.set(value, 0))

const loadStats = async () => {
  try {
    failedToLoadStats.value = false
    const returnedStats = await fetchStats()

    if (!returnedStats) {
      //display error message and exit
      failedToLoadStats.value = true
      return
    }

    totalTasks.value = 0
    totalTimeSaved.value = 0
    returnedStats.forEach((stat) => {
      if (stats.value.has(stat.task_name)) {
        stats.value.set(stat.task_name, stat.total)
        totalTasks.value += stat.total
        totalTimeSaved.value += stat.task_duration * stat.total
      }
    })

    console.log(`Stats: `, stats.value, stats.value.length)
  } finally {
    isLoading.value = false
  }
}

defineExpose({
  loadStats
})
</script>
