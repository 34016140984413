<template>
  <div v-if="errorMessage" class="text-red-500 font-semibold py-2 text-center">
    {{ errorMessage }}
  </div>
  <div class="p-4 bg-white shadow-md rounded-md max-w-md mx-auto">
    <h2 class="text-xl font-bold mb-4">Verify your account</h2>
    <p class="mb-4">
      A verification email has been sent to your email address. Please verify your email
      <strong>and refresh current tab</strong> to start your free trial. If you don't see the email
      in your inbox, please check your spam or junk folder
    </p>

    <Button
      label="Resend Verification Email"
      :disabled="isButtonDisabled"
      @click="resendVerificationEmail"
      class="w-full mb-4 border-2"
    />

    <p v-if="isButtonDisabled" class="text-sm text-gray-600">
      You can resend the email after {{ countdown }} seconds.
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Button from 'primevue/button'
import { serverUrl } from '@/globals/env'
import { useAuthStore } from '../stores/auth'
import * as Sentry from '@sentry/vue'

const authStore = useAuthStore()
const isButtonDisabled = ref(false)
const countdown = ref(60)
let countdownInterval: any
const resendURL = ref(serverUrl + 'email/resend')
const errorMessage = ref()

const resendVerificationEmail = async () => {
  try {
    isButtonDisabled.value = true
    countdown.value = 60

    // Simulate sending email
    console.log('Verification email sent')

    const response = await fetch(resendURL.value, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + authStore.user.token
      }
    })

    if (!response.ok) throw new Error(response.statusText)

    await response.json()

    countdownInterval = setInterval(() => {
      if (countdown.value > 0) {
        countdown.value--
      } else {
        clearInterval(countdownInterval)
        isButtonDisabled.value = false
      }
    }, 1000)
  } catch (err) {
    Sentry.captureException(err)

    errorMessage.value = String(err)

    if (errorMessage.value.includes('Bad Request'))
      errorMessage.value = 'Failed to send verification email.'

    isButtonDisabled.value = false
    clearInterval(countdownInterval)
  }
}

onMounted(() => {
  return () => {
    clearInterval(countdownInterval)
  }
})
</script>
