export const SUPPORTED_POSHMARK_URL_REGEX_ARR = [
  'https://*.poshmark.com/*',
  'https://poshmark.com/*',
  'https://*.poshmark.ca/*',
  'https://poshmark.ca/*'
]

export const SUBSCRIPTION_TAB_ID = 3
export const PROFILE_TAB_ID = 4
export const SKIPPED_SCHEDULED_TASK_MSG =
  'This task was skipped because another task was already in progress at the scheduled time.'
